import React from "react"
import InvitationAcceptedResponse from "../../components/body/pages/newsletter-email/invitation-accepted"
import NoheaderFooter from "../../components/Noheaderfooter"
import SEO from "../../components/seo"

const InvitationResponseRoute = () => (
  <NoheaderFooter>
    <SEO noIndex={true} title=" Kuda Premium Invite" />
    <InvitationAcceptedResponse />
  </NoheaderFooter>
)
export default InvitationResponseRoute
